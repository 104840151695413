import { post } from "../utilities/ajax";

export default class woocommerce {

    constructor() {
        // Initialize event handlers and QuickSelect functionality
        this.setupEventHandlers();
        this.event_Handler();
        this.QuickSelect();
    }


    setupEventHandlers() {
        jQuery(document.body).on("click", "button.plus, button.minus", this.updateCartQuantity.bind(this));
    }

    updateCartQuantity(event) {
        event.preventDefault();

        // Get the clicked button and the relevant cart item
        const button = jQuery(event.currentTarget); // The clicked button (plus or minus)
        const cartItem = button.closest('.mini-cart-item'); // Closest cart item
        const quantityInput = cartItem.find('.qty'); // Quantity input field
        let currentQuantity = parseInt(quantityInput.val(), 10); // Current quantity

        // Update the input field with the new quantity
        quantityInput.val(currentQuantity);

        // Extract the cart item key from the quantity input's name attribute
        const cartItemKey = quantityInput.attr('name').match(/\[(.*?)\]/)[1];

        // Prepare the data for the AJAX request
        const formData = {
            cart_item_key: cartItemKey,
            new_quantity: currentQuantity,
        };

        // Send the AJAX request to update the cart
        post('st_mini_cart_update', formData)
            .then(response => {
                if (response.status) {
                    
                    // Update the mini cart count and total
                    jQuery('#mini-cart-count').text(response.new_quantity);

                    // Update the main cart subtotal and other fields
                    jQuery('.streamit-cart-count').html(response.new_quantity);
                    jQuery('.st-woocommerce-Price-amount').html(response.new_subtotal);
                } else {
                    console.log(response.message);
                    
                }
            })
            .catch(error => {
                console.error('Error updating cart:', error);
                alert('An error occurred. Please try again.');
            });
    }

    // Event handler to manage tab switching and class updates
    event_Handler() {
        // Attach event listener to each navigation link within the product tab
        if (!document.querySelector('[class*="woocommerce columns-"]')) {
            document.querySelectorAll('.woocommerce-product-tab .nav-link').forEach((link) => {
                link.addEventListener('click', (event) => {
                    event.preventDefault(); // Prevent the default anchor behavior

                    // Get the clicked link's ID to apply to the tab
                    let linkId = link.getAttribute('id');

                    // Select the tab pane and column container elements
                    let tabPane = document.querySelector('.tab-pane');
                    let tabcol = document.querySelector('.row-col-data');

                    // Update the ID of the tabPane to match the clicked tab's ID
                    tabPane.setAttribute('id', `grid-${linkId}`);
                    tabPane.classList.add('active');

                    if (linkId === '1') {
                        // Apply grid-1-specific classes
                        tabPane.classList.add('product-list');
                        tabcol.className = 'row row-col-data gy-5 row-cols-1 row-cols-sm-1 row-cols-md-1 row-cols-lg-1 row-cols-xl-1 woocommerce poduct-slick';
                    } else {
                        // Reset classes for other grids
                        tabPane.classList.remove('product-list');
                        tabcol.className = 'row row-col-data gy-5 row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4';
                    }

                    // Remove any previous "row-cols-xl-*" class
                    tabcol.classList.forEach((className) => {
                        if (className.startsWith('row-cols-xl-') && linkId !== '1') {
                            tabcol.classList.remove(className);
                        }
                    });

                    // Add a custom class for the selected tab's grid size (if necessary)
                    if (linkId !== '1') {
                        tabcol.classList.add(`row-cols-xl-${linkId}`);
                    }
                });
            });

        } else {

            // Find all elements with classes that match "woocommerce columns-*"
            // Find all elements with classes that match "woocommerce columns-*"
            document.querySelectorAll('[class*="woocommerce columns-"]').forEach((element) => {
                const tabcol = element.querySelector('.row.row-col-data');
                if (tabcol) {
                    // Determine the column number from the class (e.g., columns-2 → 2)
                    const match = element.className.match(/columns-(\d+)/);
                    const colNum = match ? match[1] : 4; // Default to 4 columns if no match found

                    // Update classes dynamically
                    tabcol.className = `row row-col-data gy-5 row-cols-${colNum} row-cols-sm-${colNum} row-cols-md-${colNum} row-cols-lg-${colNum} row-cols-xl-${colNum} woocommerce product-slick`;

                    // Add or remove 'product-list' based on columns-1
                    if (colNum === '1') {
                        tabcol.classList.add('product-list');
                    } else {
                        tabcol.classList.remove('product-list');
                    }
                }
            });

        }
    }


    // Quick select functionality for initializing select2 dropdown
    QuickSelect() {
        // Attach event listener to the quick select button
        jQuery(document).on('click', '.woosq-btn', function () {
            // Delay the initialization of select2 for better UX
            setTimeout(() => {
                // Initialize select2 dropdown with 100% width
                jQuery('select').select2({
                    width: '100%'
                });

                // Add 'wide' class to the select2 container for styling
                jQuery('.select2-container').addClass('wide');
            }, 500); // Delay by 500ms to allow DOM updates
        });
    }
}
