import { post } from "../utilities/ajax";

export default class authentication {
    constructor() {
        this.setupEventHandlers();
        this.ShowPasswordIcone();
    }

    setupEventHandlers() {
        //submit Registration form
        jQuery(document.body).on("submit", "#streamit-registration-form", this.SubmitRegistration.bind(this));

        // Submit Login form
        jQuery(document.body).on("submit", "#streamit-login-form", this.SubmitLogin.bind(this));

        // Submit Forgot Paaword form
        jQuery(document.body).on("submit", "#streamit-forgot-password-form", this.SubmitForgotPassword.bind(this));

        //Profile Edit
        jQuery(document.body).on("submit", "#st_profile_edit", this.ProfileEdit.bind(this));

    }

    SubmitRegistration(event) {
        event.preventDefault();

        // Serialize the form data into an object
        const formObject = jQuery("#streamit-registration-form").serializeArray().reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});

        const checkboxField = jQuery("#st_term_condition");
        if (checkboxField) {
            formObject[checkboxField.attr("name")] = checkboxField.is(":checked") ? checkboxField.val() : "not_accepted";
        }

        // Submit the form data using an AJAX POST request
        post('st-user-register', formObject)
            .then(res => {
                this.handleResponseAction(res);
            })
            .catch(err => {
                this.handleResponseAction(err);
            });
    }

    SubmitLogin(event) {
        event.preventDefault();

        // Serialize the form data into an object
        const formObject = jQuery("#streamit-login-form").serializeArray().reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});

        // Submit the form data using an AJAX POST request
        post('st-user-login', formObject)
            .then(res => {
                this.handleResponseAction(res);
            })
            .catch(err => {
                this.handleResponseAction(err);
            });
    }

    SubmitForgotPassword(event) {
        event.preventDefault();

        // Serialize the form data into an object
        const formObject = jQuery("#streamit-forgot-password-form").serializeArray().reduce((obj, item) => {
            obj[item.name] = item.value;
            return obj;
        }, {});


        // Submit the form data using an AJAX POST request
        post('st-user-forgot-password', formObject)
            .then(res => {
                this.handleResponseAction(res);
            })
            .catch(err => {
                this.handleResponseAction(err);
            });
    }

    handleResponseAction(response) {
        const statusMessageElement = document.querySelector('.st-status-message');

        // Clear any existing messages and reset the background classes
        statusMessageElement.innerHTML = '';
        statusMessageElement.classList.remove('bg-danger-subtle', 'bg-success-subtle', 'p-3'); // Reset background and padding

        // Check if the response indicates success
        if (response.status) {
            // Add success classes
            statusMessageElement.classList.add('bg-success-subtle', 'p-3', 'small', 'mb-3');

            // Display success message
            const messageElement = document.createElement('span');
            messageElement.textContent = response.message; // Example: "Login successful."
            statusMessageElement.appendChild(messageElement);

            // Optionally redirect after showing the message
            if (typeof response.redirect_url !== 'undefined' && response.redirect_url !== '') {
                var redirect = response.redirect_url;

                // Show the message or perform any other actions before redirecting
                setTimeout(() => {
                    window.location.href = redirect; // Redirect to the new URL
                }, 2000); // 2 seconds delay before redirect
            } else {
                // Handle the case where there is no redirect URL provided
                setTimeout(() => {
                    location.reload();
                }, 2000); // 2 seconds delay before redirect

            }

        } else {
            // Add error classes
            statusMessageElement.classList.add('bg-danger-subtle', 'p-3', 'small', 'mb-3');

            // Display error message
            const messageElement = document.createElement('span');
            messageElement.textContent = response.message; // Example: "Invalid username or password."
            statusMessageElement.appendChild(messageElement);
        }
    }


    ShowPasswordIcone() {
        this.add_view_password_icon(jQuery('form[name="loginform"]'), '.login-password');
        this.add_view_password_icon(jQuery('form#streamit-registration-form'), '.registration-fields');
        this.add_view_password_icon(jQuery('form#streamit-login-form'), '.login-fields');
        this.add_view_password_icon(jQuery('form#pmpro_form'), '.pmpro_checkout-field-password');
    }

    add_view_password_icon($form, closest) {
        if ($form.length > 0) {
            var passwordField = $form.find('input[type="password"]');
            if (passwordField.length > 0) {
                passwordField.each(function () {
                    // Append the show password icon for each password field
                    jQuery(this).after('<i class="password_toggle show_password icon-eye-2"></i>');
                });

                // Toggle password visibility when the icon is clicked
                jQuery(document).on("click", ".show_password", function () {
                    var $passwordInput = jQuery(this).prev('input[type="password"]'); // Get the associated password input
                    $passwordInput.attr("type", "text"); // Change type to text

                    // Replace the icon to hide password
                    jQuery(this).replaceWith('<i class="password_toggle hide_password icon-eye-slash"></i>');
                });

                jQuery(document).on("click", ".hide_password", function () {
                    var $passwordInput = jQuery(this).prev('input[type="text"]'); // Get the associated password input
                    $passwordInput.attr("type", "password"); // Change type back to password

                    // Replace the icon to show password
                    jQuery(this).replaceWith('<i class="password_toggle show_password icon-eye-2"></i>');
                });
            }
        }
    }

    ProfileEdit(event) {
        event.preventDefault(); // Prevent default form submission

        // Create a new FormData object with the form data
        var formData = new FormData(event.target);

        // Get the file input element
        var fileInput = document.getElementById('upload-profile-picture');

        // Check if the user has selected a file
        if (fileInput && fileInput.files.length > 0) {
            var selectedFile = fileInput.files[0];
            // Append the selected file to the FormData object under the 'user_avatar' field
            formData.append('user_avatar', selectedFile);
        }

        // Submit the form data using an AJAX POST request
        post('st_edit_user_profile', formData)
            .then(res => {
                console.log(res);
                this.handleResponseAction(res);
            })
            .catch(err => {
                console.log(err);
                this.handleResponseAction(res);
            });

    }


}
