import 'bootstrap'; // Bootstrap for UI components
import '@popperjs/core'; // Popper.js for positioning elements
import './../icon-font/icon.css'; // Custom icon styles
import './../scss/theme.scss'; // Main theme styles

// Import  JS modules
import component from './../js/component.js';
import header from './../js/header.js';
import GeneralAjax from '../js/general-ajax.js';
import woocommerce from '../js/woocommerce.js';
import ArchiveAppend from '../js/archive-append.js';
import authentication from '../js/authentication.js';

// Document ready function
jQuery(document).ready(function ($) {
    // Initialize modules and assign to DashboardSideBarModule
    const DashboardSideBarModule = {
        'component': new component(),
        'header': new header(),
        'GeneralAjax': new GeneralAjax(),
        'woocommerce': new woocommerce(),
        'ArchiveAppend': new ArchiveAppend(),
        'authentication': new authentication()
    };

    // Attach the module to the global window object for external access
    window['DashboardSideBarModule'] = DashboardSideBarModule;

    // Initialize Select2 for dropdowns to enhance UX
    jQuery('select').select2({
        width: '100%' // Make the dropdown width 100% of its container
    });
    // Add a class to make Select2 dropdowns wide
    jQuery('.select2-container').addClass('wide');

    if (jQuery('#st_playlist_post_type').is('select')) {
        jQuery('#st_playlist_post_type').select2({
            width: '100%',
            dropdownParent: jQuery('#creatplaylistModal')
        });
    }
    
    
});
