import { get } from "../utilities/ajax";

export default class ArchiveAppend {
    constructor() {
        this.isFetching = false; // Prevent multiple requests at the same time
        this.currentPage = 1; // Track the current page for pagination
        this.listingElement = document.querySelector('.data-listing'); // Target the container to append new content

        // Initialize pagination type and settings
        this.loadMoreButton = jQuery('#css_prefix_post_load_more');
        if (this.loadMoreButton.length > 0) {
            this.setupLoadMorePagination();
        } else if (jQuery('#css_prefix_loader-wheel-container').length > 0) {
            this.setupInfiniteScrollPagination();
        }
    }

    /**
     * Setup the "Load More" button pagination
     */
    setupLoadMorePagination() {
        this.totalPages = this.loadMoreButton.data('total-pages');
        this.postType = this.loadMoreButton.data('post-type');
        this.loadingText = this.loadMoreButton.data('loading-text');
        this.originalText = this.loadMoreButton.data('original-text');
        this.perpage = this.loadMoreButton.data('per-page');
        this.extrasettings = this.loadMoreButton.data('extra-settings');

        // Attach event handler for the "Load More" button click
        this.setupEventHandlers('load_more');
    }

    /**
     * Setup the Infinite Scroll pagination
     */
    setupInfiniteScrollPagination() {
        const loader = jQuery('#css_prefix_loader-wheel-container');
        this.totalPages = loader.data('total-pages');
        this.postType = loader.data('post-type');
        this.perpage = loader.data('per-page');
        this.extrasettings = loader.data('extra-settings');

        // Attach event handler for scrolling to trigger loading more content
        this.setupEventHandlers('infinite_scroll');
    }

    /**
     * Setup event handlers based on the pagination type
     * @param {string} paginationType - Type of pagination: 'load_more' or 'infinite_scroll'
     */
    setupEventHandlers(paginationType) {
        if (paginationType === 'load_more') {
            jQuery(document.body).on("click", "#css_prefix_post_load_more", this.loadMore.bind(this));
        } else if (paginationType === 'infinite_scroll') {
            jQuery(window).on("scroll", this.handleScroll.bind(this));
        }
    }

    /**
     * Handle infinite scroll by checking if the user has reached the bottom of the page
     */
    handleScroll() {
        if (this.isFetching) return; // Prevent multiple requests

        const scrollTop = jQuery(window).scrollTop();
        const windowHeight = jQuery(window).height();
        const documentHeight = jQuery(document).height();

        // Check if the user is near the bottom of the page
        if (scrollTop + windowHeight >= documentHeight - 100) {
            const loader = jQuery('#css_prefix_loader-wheel-container');
            loader.css('display', 'block');
            this.loadMore(); // Trigger loading more content
        }
    }

    /**
     * Load more content, triggered by button click or infinite scroll
     * @param {Event} [event] - The event object from button click
     */
    loadMore(event) {

        if (event) event.preventDefault(); // Prevent default action for button click

        if (this.isFetching) return; // Prevent multiple concurrent requests

        this.isFetching = true; // Set fetching state to true to prevent new requests

        if (this.loadMoreButton.length > 0) {
            this.loadMoreButton.text(this.loadingText); // Update the button text to loading text
        }


        // Check if there are more pages to load
        if (this.currentPage > this.totalPages) {
            this.noMorePages(); // No more pages, remove "Load More" or infinite scroll loader
            return;
        }

        // Increment the current page to load the next page
        this.currentPage += 1;
        // Prepare the data to send in the request
        const data = {
            current_page: this.currentPage,
            post_type: this.postType,
            per_page: this.perpage,
            extra_setting: this.extrasettings
        };

        this.fetchData(data); // Make the AJAX request to fetch data
    }

    /**
     * Handle the case when there are no more pages to load
     */
    noMorePages() {
        if (this.loadMoreButton.length > 0) {
            this.loadMoreButton.remove(); // Remove "Load More" button
        } else {
            // Remove the infinite scroll loader when no more pages are available
            jQuery('#css_prefix_loader-wheel-container').remove();
        }
    }

    /**
     * Fetch data using AJAX
     * @param {Object} data - The data to send in the request
     */
    fetchData(data) {
        get('st_load_more_content', {
            data: data,
            _ajax_nonce: window.stAjax,
        })
            .then(res => {
                if (res.status) {
                    this.appendContent(res.result); // Append new content to the listing
                    this.updatePaginationState(); // Update the pagination button or scroll state
                } else {
                    this.noMorePages(); // Remove button or loader if no content is returned
                }
                this.isFetching = false; // Reset fetching state after the request completes
            })
            .catch(error => {
                console.error(error); // Log any errors during the fetch
                this.isFetching = false; // Reset fetching state on error
                if (this.loadMoreButton.length > 0) {
                    this.loadMoreButton.text(this.originalText); // Restore original button text
                }
            });
    }

    /**
     * Update the state of the "Load More" button or handle infinite scroll
     */
    updatePaginationState() {
        if (this.currentPage >= this.totalPages) {
            if (this.loadMoreButton.length > 0) {
                this.loadMoreButton.remove(); // Remove the "Load More" button if on the last page
            } else {
                const loader = jQuery('#css_prefix_loader-wheel-container');
                loader.remove(); 
                jQuery(window).off('scroll', this.handleScroll.bind(this)); // Stop infinite scroll
            }
        } else if (this.loadMoreButton.length > 0) {
            this.loadMoreButton.text(this.originalText); // Restore original button text
            this.loadMoreButton.data('current-page', this.currentPage); // Update the button data for next click
        }else if(jQuery('#css_prefix_loader-wheel-container').length > 0){
            const loader = jQuery('#css_prefix_loader-wheel-container');
            loader.css('display', 'none');
        }
    }

    /**
     * Append new content to the listing
     * @param {string} content - The new content to append
     */
    appendContent(content) {
        if (this.listingElement) {
            this.listingElement.insertAdjacentHTML('beforeend', content);
        }
    }
}

