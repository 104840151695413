export default class header {
    constructor() {
        this.isMobileView = (window.innerWidth <= 1199);

        this.headerAddRemoveClass();
        this.event_Handler();

    }

    event_Handler() {
        if (this.isMobileView) {
            jQuery(document.body).on("click", ".fa-caret-down", (element) => {
                element.preventDefault();
                this.headerDropDown(element);
            });
        }
    }

    headerAddRemoveClass() {
        if (this.isMobileView) {
            jQuery('#site-navigation .offcanvas-body #css_prefix-menu-container').addClass('is-mobile-view');
        }
    }

    headerDropDown(element) {

        var ParentMenuTag = jQuery(element.target).closest('.menu-item');
        if (ParentMenuTag.hasClass('sfHover')) {
            ParentMenuTag.removeClass('sfHover');

            var subMenuItems = ParentMenuTag.find('.sub-menu');
            subMenuItems.css('display', 'none');
        }
    }
}