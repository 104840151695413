import './superfish.js';
import bootstrapcomponent from './bootstrap-component.js';


export default class component {
    constructor() {
        this.slider = jQuery('.custom-tab-slider');
        this.leftArrow = jQuery('.left');
        this.rightArrow = jQuery('.right');
        this.scrollAmount = 200; // Adjust scroll distance as needed
        this.menuSelector = 'ul.sf-menu';

        const bootstrap = new bootstrapcomponent();
        bootstrap.tooltip();

        this.HeaderHeight();
        this.MenuLayout();
        this.event_Handler();
        this.HeaderScroll();
        this.ReadMore();
        this.SearchHideOptions();
        this.NavTabHandler();
        this.loader();
    }


    event_Handler() {
        jQuery(document.body).on("click", "#back-to-top", this.BacktoTop.bind(this));

        jQuery(window).scroll(() => {
            this.scrollAdditional();
        });

        jQuery(document.body).on("click", '.copy-url-btn', this.CopyUrl.bind(this));

        jQuery(document.body).on("click", 'button.plus, button.minus', this.ShopQuantity.bind(this));

        // Add /Edit comment offcanvas
        jQuery(document.body).on("click", "#openReviewButton", this.ReviewOffCanvas.bind(this));

        jQuery(window).resize(() => {
            this.HeaderHeight();
        });

        jQuery(document.body).on("click", "#edit-profile-picture-btn", this.EditProfilePicture.bind(this));

        jQuery(document.body).on('woosq_loaded woosq_close', this.QuickViewSection.bind(this));


    }


    /**
     * Handles the profile picture edit functionality.
     * 
     * @param {Event} event - The event object triggered when the edit profile button is clicked.
     * 
     * This function prevents the default button action, triggers the file input 
     * click event, and sets up an event listener to preview the selected image 
     * file once it's selected. It ensures the selected file is an image and 
     * displays the preview accordingly.
     */
    EditProfilePicture(event) {
        // Prevents the default button click behavior (e.g., form submission)
        event.preventDefault();

        // Trigger the click event of the hidden file input element when the edit button is clicked
        jQuery('#upload-profile-picture').click();

        // Listen for the file input change event when the user selects a file
        jQuery('#upload-profile-picture').on('change', (e) => {
            // Get the selected file from the file input
            var file = e.target.files[0];

            // Check if the selected file exists and is an image
            if (file && file.type.startsWith('image/')) {
                // Create a FileReader instance to read the image file
                var reader = new FileReader();

                // Once the image is successfully loaded, update the preview image source
                reader.onload = (e) => {
                    jQuery('#profile-picture-preview').attr('src', e.target.result);
                };

                // Read the image file as a base64 data URL for preview
                reader.readAsDataURL(file);
            } else {
                // If the selected file is not an image, clear the preview
                jQuery('#profile-picture-preview').attr('src', '');
            }
        });
    }


    /**
     * Handles the update of the product quantity when the plus or minus button is clicked.
     * 
     * @param {Event} event - The event object triggered by clicking the plus or minus button.
     * 
     * This function retrieves the current quantity value, checks the boundaries (min/max), 
     * and adjusts the quantity based on the button clicked (plus or minus). It also 
     * ensures that the "update cart" button is enabled after any change.
     */
    ShopQuantity(event) {
        // Get the clicked button element
        const button = jQuery(event.target).closest('button');

        // Find the quantity input field associated with the button
        const qty = button.closest('.quantity').find('.qty');

        // Enable the "update cart" button when a quantity change occurs
        jQuery('button[name="update_cart"]').removeAttr('disabled');

        // Get the current quantity value (default to 0 if not valid)
        let val = parseFloat(qty.val()) || 0;

        // Get the maximum, minimum, and step values from the input attributes
        const max = parseFloat(qty.attr('max')) || Infinity;
        const min = parseFloat(qty.attr('min')) || 0;
        const step = parseFloat(qty.attr('step')) || 1;

        // Handle the "plus" button click (increase quantity)
        if (button.hasClass('plus')) {
            // Increase the quantity, ensuring it doesn't exceed the max limit
            qty.val(val < max ? val + step : max);
        }
        // Handle the "minus" button click (decrease quantity)
        else if (button.hasClass('minus')) {
            // Decrease the quantity, ensuring it doesn't go below the min limit
            qty.val(val > min ? val - step : min);
        }
    }


    /**
     * Copies the URL from the input field to the clipboard.
     * 
     * @param {Event} event - The event object triggered when the copy button is clicked.
     * 
     * This function prevents the default button action, selects the URL in the input field, 
     * and copies it to the clipboard using the `document.execCommand('copy')` method.
     */
    CopyUrl(event) {
        // Prevents the default button action (e.g., form submission or navigation)
        event.preventDefault();

        // Select the content of the input field with the class "copy-post-url"
        jQuery('.copy-post-url').select();

        // Execute the 'copy' command to copy the selected text to the clipboard
        try {
            // Modern browsers support `document.execCommand()` for copying content
            const successful = document.execCommand('copy');

            // Optionally, provide feedback to the user (you can use an alert or a UI update)
            if (successful) {
                console.log('URL copied to clipboard');
            } else {
                console.log('Failed to copy URL');
            }
        } catch (err) {
            // Handle errors (for example, in cases where `document.execCommand()` is not supported)
            console.error('Unable to copy URL: ', err);
        }
    }


    /**
     * Manages the layout and behavior of the menu, adapting for responsive/mobile views
     * and enabling appropriate functionality for hover or touch events.
     */
    MenuLayout() {
        var $menu = jQuery(this.menuSelector);

        // Check if the screen is in a responsive (mobile) view
        if (this.isResponsiveView()) {

            // Destroy hover events to avoid issues on mobile (but keep Superfish for animation)
            this.removeHoverEvents();

            // Optionally, add touch event to simulate hover-like functionality on mobile
            this.addTouchEventForMobile();

        } else {
            // Enable Superfish for larger screens with animation and auto arrows
            $menu.superfish({
                delay: 500,  // Delay before submenus open/close
                animation: { opacity: 'show', height: 'show' },  // Simple fade and slide animation
                speed: 'fast',  // Animation speed
                cssArrows: true,  // Use CSS arrows for submenu items
                autoArrows: true,  // Automatically adds arrows for parent menu items
            });
        }
    }

    /**
     * Function to check if the screen width is mobile (responsive view).
     * 
     * @returns {boolean} True if the screen is mobile (<= 768px), false otherwise.
     */
    isResponsiveView() {
        return window.innerWidth <= 768;  // Adjust the threshold as needed
    }

    /**
     * Removes hover events on the menu for mobile views to prevent unwanted interactions.
     */
    removeHoverEvents() {
        var $menu = jQuery(this.menuSelector);

        // Remove hover events from all <li> items
        $menu.find('li').off('mouseenter mouseleave');
    }

    /**
 * Adds touch/click events to simulate hover behavior on mobile for menu items.
 * Ensures only one submenu is visible at a time and handles touch events for toggling submenus.
 */
    addTouchEventForMobile() {
        var $menu = jQuery(this.menuSelector);

        // Add touch/click event for mobile to toggle submenus
        $menu.find('li.menu-item-has-children').on('click touchstart', function (event) {
            var $li = jQuery(this); // Current <li> element
            var $submenu = $li.children('ul.sub-menu'); // Submenu under the clicked <li>
            var $toggleIcon = $li.find('.toggledrop'); // Toggle icon

            // Ensure the clicked element is the toggle icon or its child
            if (jQuery(event.target).is($toggleIcon) || jQuery(event.target).closest('.toggledrop').length) {
                event.preventDefault(); // Prevent default link behavior
                event.stopPropagation(); // Prevent bubbling to parent elements

                // Toggle the current submenu visibility
                if ($submenu.is(':visible')) {
                    $submenu.slideUp(300); // Close if visible
                    $li.removeClass('open'); // Remove open class
                } else {
                    // Close all other open submenus except the current hierarchy
                    $menu.find('ul.sub-menu:visible')
                        .not($submenu)
                        .not($li.parents('.menu-item-has-children').children('ul.sub-menu'))
                        .slideUp(300);

                    // Remove 'open' class from other open <li> elements
                    $menu.find('li.open')
                        .not($li)
                        .not($li.parents('li.open'))
                        .removeClass('open');

                    // Open the current submenu
                    $submenu.slideDown(300);
                    $li.addClass('open'); // Add 'open' class
                }
            } else {
                // Allow navigation if the clicked element is not a toggle
                if (!$submenu.length) {
                    return true; // Let the link behave as usual
                }
            }
        });
    }


    /**
     * Scrolls the page back to the top when the back-to-top button is clicked.
     * 
     * @param {Event} event - The event object triggered by the click event on the button.
     * 
     * This function prevents the default click behavior and smoothly scrolls the 
     * page to the top using jQuery's animate() method. The scrolling is set to 
     * a "slow" speed with a "linear" easing function for a smooth experience.
     */
    BacktoTop(event) {
        // Prevents the default action for the click event (e.g., following a link)
        event.preventDefault();

        // Animate the scrollTop property of the <html> and <body> elements to 0, 
        // which scrolls the page to the top. The "slow" duration and "linear" easing 
        // create a smooth scrolling effect.
        jQuery("html, body").animate({ scrollTop: 0 }, 600, "linear");  // Set custom time (600ms) instead of "slow" for clarity
    }

    /**
     * Adds or removes the "header-sticky" class to the header when the page is scrolled.
     * This ensures that the header becomes sticky at the top of the page when scrolling down.
     * 
     * @function HeaderScroll
     * @memberof custom
     * @instance
     * @returns {void} This function does not return a value.
     */
    HeaderScroll() {
        // Select the header element that has the 'has-sticky' class
        const js_prefix_headerClass = document.querySelector('header.has-sticky');

        // Check if the header exists before adding the scroll event listener
        if (js_prefix_headerClass !== null) {
            // Add a scroll event listener to the window to track scrolling
            window.addEventListener('scroll', () => {
                // Check if the page has been scrolled down by any amount
                if (document.documentElement.scrollTop > 0) {
                    // If scrolled, add the "header-sticky" class to the header
                    js_prefix_headerClass.classList.add("header-sticky");
                } else {
                    // If at the top, remove the "header-sticky" class
                    js_prefix_headerClass.classList.remove("header-sticky");
                }
            });
        }
    }

    /**
     * Sets a custom CSS property for the header height to allow dynamic adjustments.
     * The height is calculated and applied in 'em' units for better scalability across the design.
     * 
     * @function HeaderHeight
     * @memberof custom
     * @instance
     * @returns {void} This function does not return a value.
     */
    HeaderHeight() {
        // Select the header element with the 'header-default' class
        const header = jQuery('.header-default');

        // Get the current height of the header
        let headerHeight = header.height();

        // Set the custom '--header-height' CSS property in 'em' units
        // This makes the header height available for dynamic adjustments in CSS
        jQuery('html').css('--header-height', (headerHeight / 16) + 'em');
    }


    /**
     * Controls the visibility of the "back to top" button based on the page's scroll position.
     * When the user scrolls more than 100px, the button will fade in. If they scroll back up,
     * it fades out.
     * 
     * @function scrollAdditional
     * @memberof custom
     * @instance
     * @returns {void} This function does not return a value.
     */
    scrollAdditional() {
        // Get the current scroll position from the top of the page
        let scrollTop = jQuery(window).scrollTop();

        // Check if the scroll position is greater than 100px
        if (scrollTop > 100) {
            // If scrolled more than 100px, fade in the 'back-to-top' button
            jQuery('#back-to-top').fadeIn();
        } else {
            // If scrolled back up (less than 100px), fade out the 'back-to-top' button
            jQuery('#back-to-top').fadeOut();
        }
    }


    ReadMore() {
        // Get all 'readmore-wrapper' elements
        let wrappers = document.querySelectorAll('.readmore-wrapper');

        wrappers.forEach(function (wrapper) {
            let button = wrapper.querySelector('.readmore-btn');
            if (button == null) {
                return;
            }

            let readmoreText = wrapper.querySelector('.readmore-text');

            // Check if content exceeds three lines
            if (readmoreText.scrollHeight > readmoreText.clientHeight) {
                button.style.display = "inline-block";
            }

            // Add click event listener to the button
            button.addEventListener('click', function () {
                if (readmoreText.classList.contains('active')) {
                    readmoreText.classList.remove('active');
                    button.querySelector('.show-more-text').style.display = "inline";
                    button.querySelector('.show-less-text').style.display = "none";
                    button.classList.remove('bg-primary');
                    button.classList.add('bg-secondary');
                } else {
                    readmoreText.classList.add('active');
                    button.querySelector('.show-more-text').style.display = "none";
                    button.querySelector('.show-less-text').style.display = "inline";
                    button.classList.remove('bg-secondary');
                    button.classList.add('bg-primary');
                }
            });
        });
    }

    /**
     * Clears the search input field and hides the remove search text icon.
     * Triggered when the user clicks on a button or icon to clear the search input.
     * 
     * @function RemoveSearchText
     * @memberof custom
     * @instance
     * @param {Event} event - The event object representing the user action.
     * @returns {void} This function does not return a value.
     */
    RemoveSearchText(event) {
        // Prevent the default action for the event (e.g., submitting the form)
        event.preventDefault();

        // Find the search input field within the closest form and clear its value
        const searchInput = jQuery(event.target).closest('form').find('input[name="s"]');
        searchInput.val('');

        // Hide the 'remove_search_text' element (likely an icon or button)
        jQuery('#remove_search_text').hide();
    }


    /**
     * Toggles the visibility of the cross button (clear search button) based on the search input value.
     * If the search input is empty, the cross button will be hidden; otherwise, it will be shown.
     * 
     * @function ToggleCrossButtonVisibility
     * @memberof custom
     * @instance
     * @returns {void} This function does not return a value.
     */
    ToggleCrossButtonVisibility() {
        // Select the form and search input field
        const form = jQuery('#st_search_page_form');
        const searchInput = form.find('input[name="s"]');

        // Select the cross button (clear search text)
        const crossButton = form.find('#remove_search_text');

        // Check if the search input is empty or not
        if (searchInput.val().trim() === '') {
            // If input is empty, hide the cross button
            crossButton.hide();
        } else {
            // If input is not empty, show the cross button
            crossButton.show();
        }
    }


    /**
     * Manages the visibility and interactions of the search input and related elements.
     * - Toggles the visibility of the cross button when the input value changes.
     * - Clears the search input and hides the cross button when clicked.
     * - Toggles the visibility of the search input when clicking the search icon.
     * - Hides the search input and clears the search results when clicking outside the search dropdown or input field.
     * - Prevents closing the search input when clicking inside the input or search result section.
     * 
     * @function SearchHideOptions
     * @memberof custom
     * @instance
     * @returns {void} This function does not return a value.
     */
    SearchHideOptions() {
        const form = jQuery('#st_search_page_form');

        // Listen for input changes in the search field
        form.find('input[name="s"]').on('input', () => {
            // Toggle the cross button visibility based on search input
            this.ToggleCrossButtonVisibility();
        });

        // Listen for click on the remove search button and clear the input field
        form.on('click', '#remove_search_text', (event) => {
            // Call RemoveSearchText to clear the input and hide the cross button
            this.RemoveSearchText(event);
        });

        // Toggle the visibility of the search input when clicking the search icon
        jQuery('#st-search-drop').on('click', function (e) {
            e.preventDefault(); // Prevent default action (e.g., link behavior)
            e.stopPropagation(); // Prevent the event from bubbling up to parent elements
            // Toggle the 'show' class to display/hide the search input
            jQuery('#header_search_input').toggleClass('show');
        });

        // Close the search input and clear search results if clicking outside the dropdown and input
        jQuery(window).on('click', function (e) {
            var target_element = jQuery(e.target);

            // Check if the click is outside the dropdown, input field, and search result section
            if (!target_element.closest('#st-search-drop').length &&
                !target_element.closest('#header_search_input').length &&
                !target_element.closest('.search_result_section').length) {

                // Close the search input and clear the search query and results
                jQuery('#header_search_input').removeClass('show');
                jQuery('#search-query').val('');
                jQuery('.search_result_section').empty();
            }
        });

        // Prevent the search input and results section from closing when clicked inside
        jQuery('#header_search_input, .search_result_section').on('click', function (e) {
            e.stopPropagation(); // Prevent the click event from propagating up to the window
        });
    }

    /**
     * Handles the review process for the off-canvas form by updating the review, rating, and ID fields based on the clicked button's data attributes.
     * 
     * - Retrieves custom data (`cm-review`, `cm-rating`, `cm-id`) from the clicked button.
     * - Updates the form fields with the retrieved data.
     * - Sets the rating value if available, otherwise clears the selected rating.
     * 
     * @function ReviewOffCanvas
     * @memberof custom
     * @instance
     * @param {Object} event - The event object triggered by the click event.
     * @returns {void} This function does not return a value.
     */
    ReviewOffCanvas(event) {
        event.preventDefault();

        // Capture the clicked button and retrieve custom data attributes with fallback values
        var addButton = jQuery(event.currentTarget);  // Use 'addButton' for consistency with camel case
        var review = addButton.data('cm-review') || '';  // Default to empty string if not defined
        var rating = addButton.data('cm-rating') || '';  // Default to empty string if not defined
        var id = addButton.data('cm-id') || '';  // Default to empty string if not defined

        // Update the review input field with the review text
        jQuery('#cm_details').val(review);

        // Update the comment ID input field with the retrieved ID
        jQuery('#cm_id').val(id);

        // Set the selected rating value if available; otherwise, clear all rating radio buttons
        if (rating) {
            jQuery(`input[name="rating"][value="${rating}"]`).prop('checked', true);
        } else {
            jQuery('input[name="rating"]').prop('checked', false);  // Clear any checked radio buttons
        }
    }


    /**
     * Handles navigation tab functionality by initializing arrow visibility, setting up event listeners for arrow clicks, 
     * and adding drag functionality for sliding the content.
     * 
     * This function ensures that the slider is properly initialized and sets up listeners for both left and right arrows,
     * as well as for dragging the slider.
     * 
     * @function NavTabHandler
     * @memberof custom
     * @instance
     * @returns {void} This function does not return a value.
     */
    NavTabHandler() {
        // Ensure the slider exists before trying to add event listeners
        if (this.slider.length) {

            // Initialize arrow visibility and set up initial event listeners on page load
            this.updateArrowVisibility();

            // Check if leftArrow and rightArrow are valid jQuery elements before attaching click event listeners
            if (this.leftArrow.length) {
                this.leftArrow.on('click', (event) => this.slide('left', event));
            } else {
                console.warn('Left arrow element is missing.');
            }

            if (this.rightArrow.length) {
                this.rightArrow.on('click', (event) => this.slide('right', event));
            } else {
                console.warn('Right arrow element is missing.');
            }

            // Add drag functionality if applicable
            this.addSlideDrag();
        }
    }


    /**
     * Slides the container based on the direction (left or right).
     * 
     * This function adjusts the scroll position of the slider container based on the specified direction.
     * It also updates the visibility of the left and right arrows.
     * 
     * @param {string} direction - The direction to scroll ('left' or 'right').
     * @param {Event} event - The event object, passed from the click handler.
     */
    slide(direction, event) {
        // Check if slider exists before proceeding
        if (this.slider.length) {
            const container = this.slider;

            // Adjust the scroll position based on the direction
            if (direction === 'left') {
                container.scrollLeft(container.scrollLeft() - this.scrollAmount);
            } else if (direction === 'right') {
                container.scrollLeft(container.scrollLeft() + this.scrollAmount);
            }

            // Update the visibility of the arrows based on the new scroll position
            this.updateArrowVisibility();
        } else {
            console.warn('Slider element not found.');
        }
    }

    /**
     * Updates the visibility of the left and right arrows based on the scroll position.
     * 
     * This function checks the current scroll position of the slider container and 
     * shows or hides the left and right arrows accordingly.
     */
    updateArrowVisibility() {
        // Check if slider exists before proceeding
        if (this.slider.length) {
            const scrollPosition = this.slider.scrollLeft();
            const maxScroll = this.slider[0].scrollWidth - this.slider.width();

            // Show or hide the left arrow based on scroll position
            if (scrollPosition > 0) {
                this.leftArrow.show();
            } else {
                this.leftArrow.hide();
            }

            // Show or hide the right arrow based on scroll position
            if (scrollPosition < maxScroll) {
                this.rightArrow.show();
            } else {
                this.rightArrow.hide();
            }
        } else {
            console.warn('Slider element not found.');
        }
    }

    /**
     * Adds drag functionality to the slider for manual scrolling.
     * 
     * This function enables the ability to drag the slider horizontally by holding 
     * and dragging the mouse, while also updating the visibility of the arrows 
     * during dragging.
     */
    addSlideDrag() {
        const eslider = this.slider[0]; // Assuming only one slider for simplicity
        let isDown = false;
        let startX;
        let scrollLeft;
        const maxScroll = eslider.scrollWidth - eslider.clientWidth - 20;

        // Mouse down event to start dragging
        eslider.addEventListener('mousedown', (e) => {
            isDown = true;
            eslider.classList.add('active');
            startX = e.pageX - eslider.offsetLeft;
            scrollLeft = eslider.scrollLeft;
        });

        // Mouse leave event to stop dragging if the mouse leaves the slider
        eslider.addEventListener('mouseleave', () => {
            isDown = false;
            eslider.classList.remove('active');
        });

        // Mouse up event to stop dragging when the mouse button is released
        eslider.addEventListener('mouseup', () => {
            isDown = false;
            eslider.classList.remove('active');
        });

        // Mouse move event to scroll the slider during dragging
        eslider.addEventListener('mousemove', (e) => {
            if (!isDown) return;  // Only proceed if the mouse is pressed
            e.preventDefault();
            const x = e.pageX - eslider.offsetLeft;
            const walk = (x - startX) * 3; // Scroll faster during drag
            eslider.scrollLeft = scrollLeft - walk;

            // Update arrow visibility based on the new scroll position
            this.updateArrowVisibilityDuringDrag(eslider, maxScroll);
        });
    }

    /**
     * Updates the visibility of the arrows during dragging based on the scroll position.
     * 
     * @param {HTMLElement} eslider - The slider element being dragged.
     * @param {number} maxScroll - The maximum scroll position allowed.
     */
    updateArrowVisibilityDuringDrag(eslider, maxScroll) {
        if (eslider.scrollLeft > maxScroll) {
            this.rightArrow.hide();
        } else {
            this.rightArrow.show();
        }

        if (eslider.scrollLeft === 0) {
            this.leftArrow.hide();
        } else {
            this.leftArrow.show();
        }
    }

    QuickViewSection() {
        jQuery('#woosq-popup select').select2({
            theme: 'bootstrap4',
            allowClear: false,
        });
    }

    loader(){
        jQuery('#loading').hide();
    }
}




