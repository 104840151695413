import { post, get } from "../utilities/ajax";

// Import Plyr for video players
import Plyr from 'plyr';
import 'plyr/dist/plyr.css';

import bootstrapcomponent from './bootstrap-component.js';

export default class GeneralAjax {
    constructor() {
        this.bootstrap = new bootstrapcomponent();

        this.setupEventHandlers();
        this.AjaxSearch();
        this.WatchListConfig();
        this.plyrio();


    }

    setupEventHandlers() {

        //WatchList Handler
        jQuery(document.body).on("click", ".watch-list-btn", this.WatchlistHandler.bind(this));

        //Like Handler
        jQuery(document.body).on("click", ".st-like-btn", this.LikeHandler.bind(this));

        //submit comment form
        jQuery(document.body).on("submit", "#st_comment_form", this.SubmitCommentForm.bind(this));

        // creat playlist
        jQuery(document.body).on("submit", "#st_creat_playlist", this.creatPlaylist.bind(this));

        //Add To Playlist
        jQuery(document.body).on("click", ".st_manage_playlist", this.ManagePlaylist.bind(this));

        //delete review
        jQuery(document.body).on("click", "#st_delete_comment", this.DeleteComment.bind(this));

        //delete playlist
        jQuery(document.body).on("click", ".delete_user_playlist", this.DeletPlaylist.bind(this));

        //Manage Playlist
        jQuery(document.body).on("click", ".manage_playlist", this.InsetUpdatePlaylistForm.bind(this));

        //Edit Profile information
        jQuery(document.body).on("click", "#edit-profile-picture-btn", this.setupAvatarUploader.bind(this));

        //Upload Avtar
        jQuery(document.body).on("change", "#upload-profile-picture", this.handleFileChange.bind(this));

        //Remove Avtar
        jQuery(document.body).on("click", "#remove-profile-picture-btn", this.RemoveAvtar.bind(this));

        //Email subscription
        jQuery(document.body).on('submit', '#st-subscription-form', this.SubmitSubscriptionForm.bind(this));

        //Notification action handler
        jQuery(document.body).on('click', '.notification-action-btn', this.UpdateNotificationStatus.bind(this));


    }

    plyrio() {
        // Initialize Plyr for streamit player controls
        const controls = jQuery('.streamit-player-ctrl').data('player_controls');
        if (controls) {
            // Initialize the Plyr player with  controls
            const player = new Plyr('#streamit_player', controls);
        }

        // Auto-play trailer after delay and apply trailer-specific styles
        const trailerControls = jQuery('.streamit-trailer-player-ctrl').data('player_controls');
        if (trailerControls) {
            setTimeout(function () {
                // Initialize the trailer player with  controls
                const player = new Plyr('.streamit_trailer_player', trailerControls);
                player.on('ready', () => {
                    // Play the trailer when it's ready
                    player.play();
                    // Add the trailer-play class to apply styles
                    jQuery('.video-section').addClass('trailer-play');
                });
            }, 2000); // Delay trailer playback by 2 seconds
        }
    }

    WatchListConfig() {
        const controls = jQuery('.streamit-player-ctrl').data('player_controls');
        const post_type = jQuery('.streamit-player-ctrl').data('post_type');
        const user_id = jQuery('.streamit-player-ctrl').data('user_id');
        const post_id = jQuery('.streamit-player-ctrl').data('post_id');
        const player = new Plyr('#streamit_player', controls);
        const currentTime = jQuery('.streamit-player-ctrl').data('current_time');
        if (currentTime > 5) {
            player.currentTime = currentTime
        }
        let lastUpdateTime = 0;
        player.on('timeupdate', (event) => {
            const currentTime = event.detail.plyr.currentTime
            const totalTime = event.detail.plyr.duration

            if (currentTime > 180) {
                this.UpdateWatchedTime(currentTime, post_type, user_id, totalTime, post_id);
                lastUpdateTime = currentTime;
            }
        });
        player.on('play', (event) => {
            const currentTime = player.currentTime
            const totalTime = player.duration
            this.UpdateWatchedTime(currentTime, post_type, user_id, totalTime, post_id);
            lastUpdateTime = currentTime;
        });
        player.on('pause', (event) => {
            const currentTime = player.currentTime
            const totalTime = player.duration
            this.UpdateWatchedTime(currentTime, post_type, user_id, totalTime, post_id);
            lastUpdateTime = currentTime;
        });
    }

    UpdateWatchedTime(currentTime, post_type, user_id, totalTime, post_id) {
        post('contine_watched_update', { watched_time: currentTime, post_type: post_type, user_id: user_id, watched_total_time: totalTime, post_id: post_id })
            .then((res) => {
                //success event
            })
            .catch((e) => {
                console.log(e);
            })
    }

    setupAvatarUploader(event) {
        event.preventDefault();

        document.getElementById('upload-profile-picture').click();
    }

    handleFileChange(event) {
        const file = event.target.files[0];
        if (!file) return;

        const formData = new FormData();
        formData.append('action', 'upload_profile_picture');
        formData.append('profile_picture', file);

    }

    RemoveAvtar(event) {
        event.preventDefault();
        const avatarPreview = jQuery("#profile-picture-preview");
        const defaultAvatar = jQuery("#default_avatar").val();
        const removeAvatarField = jQuery("#remove_avatar");
        const is_remove = jQuery("#is_remove_avtar");

        // Set the avatar preview to the default avatar
        avatarPreview.attr("src", defaultAvatar);

        // Update the hidden field to indicate avatar removal
        removeAvatarField.val(defaultAvatar);
        is_remove.val(1);

        // Hide the button that triggered this event
        jQuery(event.target).hide();
    }

    // Submit Subscription Form
    SubmitSubscriptionForm(event) {
        // Prevent the default form submission behavior
        event.preventDefault();

        this.bootstrap.hideToast('stToastMessage');

        let submitButton = jQuery('#st-subscribe-btn');
        submitButton.attr('type', 'button'); // Change type to 'button' to prevent form submission

        // Show the loader and hide the submit text (assuming you have a loader inside the button)
        let loader = submitButton.find('.st-loader');
        loader.removeClass('d-none'); // Show the loader
        submitButton.prop('disabled', true);

        // Gather form data
        let formData = {
            email: jQuery('#st-subscription-form input[name="EMAIL"]').val(), // Get the email input value
        };

        // Send the form data to the server using an AJAX POST request
        post('submit_subscription_form', formData)
            .then(res => {
                this.bootstrap.showToast('stToastMessage', res.message);

                setTimeout(() => {
                    loader.addClass('d-none'); // Hide the loader
                    submitButton.prop('disabled', false);
                    submitButton.attr('type', 'submit');
                }, 2000);

                // Clear the email field after successful submission
                if (res.status) {
                    jQuery('#st-subscription-form input[name="EMAIL"]').val('');
                }

                console.log(res); // Log the server response
            })
            .catch(err => {
                // Handle errors
                console.log(err);
                this.bootstrap.showToast('stToastMessage', 'Something went wrong. Please try again.');
            });
    }

    UpdateNotificationStatus(event) {
        const seenBtn = jQuery(event.currentTarget); // The clicked element
        const notification_id = seenBtn.data('notification_id');
        const user_id = seenBtn.data('user_id');
        const read = seenBtn.data('read');
    
        // Check if the clicked element has an <i> tag and update its class
        const icon = seenBtn.find('i');
        if (icon.length > 0) {
            icon.attr('class', 'st-loader');
        }
    
        // Send the AJAX request
        post('update_notification_seen_status', { user_id: user_id, notification_id: notification_id, is_seen: read })
            .then((res) => {
                console.log(res);
                window.location.reload();
            })
            .catch((e) => {
                console.error(e);
            });
    }
    

    /**
      * Handles the "Like" button functionality.
      * Toggles the like state (like/unlike) and updates the UI accordingly.
      * Makes an AJAX request to update the like status for a post.
      * 
      * @param {Event} e - The click event on the like button.
      */
    LikeHandler(e) {
        // Get the button that was clicked
        const likeBtn = jQuery(e.currentTarget);

        // Retrieve data attributes from the button (post ID and post type)
        const post_id = likeBtn.data('post_id');  // The ID of the post being liked/unliked
        const post_type = likeBtn.data('post_type');  // The type of the post (e.g., post, comment, etc.)

        // Send an AJAX request to manage the like status of the post
        post('manage_post_like', { post_id: post_id, post_type: post_type })
            .then(res => {
                // Check if the response indicates that the like action was processed
                if (res['do_like'] === true) {
                    // Dispose of the tooltip to refresh it with updated data
                    likeBtn.tooltip('dispose');

                    // Check if the post is liked
                    if (res['is_liked'] === true) {
                        // Mark the post as liked
                        likeBtn.addClass('is-liked');  // Add the 'is-liked' class to the button
                        likeBtn.find('i').removeClass('icon-heart').addClass('icon-heart-fill');  // Change the icon to a filled heart
                        likeBtn.attr('data-bs-title', 'Unlike');  // Update the tooltip text to 'Unlike'
                    } else {
                        // Mark the post as not liked
                        likeBtn.removeClass('is-liked');  // Remove the 'is-liked' class from the button
                        likeBtn.find('i').removeClass('icon-heart-fill').addClass('icon-heart');  // Change the icon back to an empty heart
                        likeBtn.attr('data-bs-title', 'Like');  // Update the tooltip text to 'Like'
                    }

                    // Reinitialize the tooltip with the updated text
                    likeBtn.tooltip();
                }

            })
            .catch(err => {
                // In case of any error during the AJAX request, log the error to the console
                console.log(err);  // Log any error to the console
            });
    }


    /**
     * Handles the Watchlist button functionality.
     * Toggles the button state (add/remove from watchlist) and updates the UI accordingly.
     * Makes an AJAX request to update the user's watchlist on the server.
     *
     * @param {Event} event - The click event on the watchlist button.
     */
    WatchlistHandler(event) {
        const button = jQuery(event.currentTarget);

        // Retrieve data attributes from the button
        const postId = button.data('post-id');
        const postType = button.data('post-type');
        const action = button.data('action');

        // Prepare data to send to the server
        const data = { post_id: postId, post_type: postType, update_action: action };

        // Send AJAX request
        post('manage_watch_list_data', data)
            .then(res => {
                if (res.success === true) {
                    button.tooltip('dispose'); // Dispose tooltip to refresh

                    // Update button based on action
                    if (action === 'add') {

                        button.addClass('in-watchlist')
                            .find('i').removeClass('icon-plus').addClass('icon-check-2');
                        button.attr('data-bs-title', 'Remove from watchlist').data('action', 'remove');

                    } else {

                        if (button.closest('.profile-page').length > 0) {
                            button.closest('.col').remove();
                        } else {
                            button.removeClass('in-watchlist')
                                .find('i').removeClass('icon-check-2').addClass('icon-plus');
                            button.attr('data-bs-title', 'Add to watchlist').data('action', 'add');
                        }
                    }

                    button.tooltip(); // Reinitialize tooltip

                    // Trigger a custom event with updated data
                    jQuery(button).closest('.cloned-item').trigger('watchlistUpdated', {
                        postId: postId,
                        postType: postType,
                        action: action
                    });

                } else {
                    console.error('Failed to update watchlist:', res);
                }
            })
            .catch(err => console.error('AJAX error:', err));
    }




    /**
     * Handles the form submission for adding or updating a comment.
     * Sends the comment data to the server using an AJAX request.
     * 
     * @param {Event} event - The submit event of the comment form.
     */
    SubmitCommentForm(event) {
        // Prevent the default form submission behavior
        event.preventDefault();

        this.bootstrap.hideToast('stToastMessage');

        let submitButton = jQuery('#st_comment_form button[type="submit"]');
        submitButton.attr('type', 'button'); // Change type to 'button' to prevent form submission

        // Show the loader and hide the submit text
        let loader = submitButton.find('.st-loader');
        loader.removeClass('d-none');  // Show the loader
        submitButton.prop('disabled', true);

        // Gather the form data into an object
        let formData = {
            comment_id: jQuery('#cm_id').val(),  // The comment ID (if updating an existing comment)
            post_type: jQuery('#cm_post_type').val(),  // The type of the post (e.g., movie, article)
            post_id: jQuery('#cm_post_id').val(),  // The ID of the post being commented on
            user_name: jQuery('#cm_name').val(),  // The name of the user submitting the comment
            user_email: jQuery('#cm_email').val(),  // The email of the user submitting the comment
            rating: jQuery('input[name="rating"]:checked').val() || 0,  // The rating given (defaults to 0 if not selected)
            cm_details: jQuery('#cm_details').val(),  // The content of the comment
        }

        // Send the comment data to the server using an AJAX POST request
        post('submit_comment_form', formData)
            .then(res => {
                this.bootstrap.showToast('stToastMessage', res.message);

                setTimeout(() => {
                    loader.addClass('d-none');  // Hide the loader
                    submitButton.prop('disabled', false);
                    submitButton.attr('type', 'submit');
                }, 2000);

                // Reload the page after successful comment submission               
                if (res.status == true) {
                    setTimeout(() => {
                        location.reload();
                    }, 2000);
                }

                // Log the server response (for debugging or confirmation)
                console.log(res);
            })
            .catch(err => {
                // Log any errors that occur during the AJAX request
                console.log(err);
            })
    }


    /**
     * Initializes the search input field with an event listener for real-time search functionality.
     * Implements debounce to reduce the number of requests sent during typing.
     */
    AjaxSearch() {
        // Get the search input element by its ID
        this.searchInput = document.getElementById('search-query');

        // Exit if the search input element does not exist
        if (this.searchInput == null) {
            return;
        }

        // Initialize a timeout variable for debounce functionality
        this.debounceTimeout = null;

        // Set up the event listener for the 'input' event on the search input field
        if (this.searchInput) {
            this.searchInput.addEventListener('input', (event) => {
                // Clear the previous timeout to avoid making too many requests in quick succession
                clearTimeout(this.debounceTimeout);

                // Set a new timeout to trigger the search after 300ms of inactivity
                this.debounceTimeout = setTimeout(() => {
                    // Get the trimmed query from the input field
                    const query = event.target.value.trim();

                    // If the query is not empty, process the search, otherwise clear the search results
                    if (query.length > 0) {
                        this.processSearch(query);
                    } else {
                        // Clear the results if the query is empty
                        document.querySelector('.search_result_section').innerHTML = '';
                    }
                }, 300);
            });
        }
    }

    /**
     * Processes the search by sending the query to the server and updating the result section.
     * 
     * @param {string} query - The search query to send to the server.
     */
    processSearch(query) {
        // Send the search query to the server using a GET request with the necessary data
        get('st_search_data', {
            data: query,               // The search query
            _ajax_nonce: window.stAjax, // The nonce for security (from WordPress AJAX)
        })
            .then(res => {
                // Assuming 'res' contains the HTML or data for results, update the result section
                const resultSection = document.querySelector('.search_result_section');
                resultSection.innerHTML = res;
            })
            .catch(error => {
                // Log any errors that occur during the request
                console.error(error);
            });
    }


    /**
     * Handles the event for creating a new playlist.
     * 
     * @param {Object} event - The event triggered by the user interaction (e.g., form submission).
     */
    creatPlaylist(event) {
        event.preventDefault(); // Prevent the default behavior of the event (e.g., form submission).
        this.bootstrap.hideToast('stToastMessage');

        let submitButton = jQuery('#st_creat_playlist button[type="submit"]');
        submitButton.attr('type', 'button'); // Change type to 'button' to prevent form submission

        // Show the loader and hide the submit text
        let loader = submitButton.find('.st-loader');
        loader.removeClass('d-none');  // Show the loader
        submitButton.prop('disabled', true);

        // Prepare the form data to be sent via AJAX
        let formData = {
            post_type: jQuery('#st_playlist_post_type').val(),  // Get the playlist post type
            playlist_title: jQuery('#st_playlist_title').val(), // Get the playlist title
            playlist_id: jQuery('#st_playlist_id').val(),       // Get the playlist ID
        };

        // Send an AJAX request to add the playlist
        post('add_playlist', formData)
            .then(res => {
                this.bootstrap.showToast('stToastMessage', res.message);

                setTimeout(() => {
                    loader.addClass('d-none');  // Hide the loader
                    location.reload();
                }, 2000);

                console.log(res);  // Log the response to the console for debugging
            })
            .catch(err => {
                location.reload(); // Reload the page even if there's an error (may want to handle errors more gracefully)
                console.log(err);  // Log the error to the console for debugging
            });
    }


    ManagePlaylist(event) {
        var checkbox = jQuery(event.target);

        this.bootstrap.hideToast('stToastMessage');

        const playlist_id = checkbox.data('playlist_id');
        const post_type = checkbox.data('post_type');
        const post_id = checkbox.data('post_id');
        const isChecked = checkbox.is(':checked');

        var formData = {
            playlist_id: playlist_id,
            post_type: post_type,
            post_id: post_id,
            is_checked: isChecked
        }

        post('add_in_playlist', formData)
            .then(res => {
                this.bootstrap.showToast('stToastMessage', res.message);

                console.log(res);
            })
            .catch(err => {
                console.log(err);
            })

    }

    DeleteComment(event) {
        event.preventDefault();

        var removebutton = jQuery(event.currentTarget);
        const comment_id = removebutton.data('comment_id');
        const post_id = removebutton.data('post_id');
        const post_type = removebutton.data('post_type');

        // Show the loader and hide the text
        removebutton.find('.st-loader').removeClass('d-none');  // Show loader

        var formData = {
            comment_id: comment_id,
            post_id: post_id,
            post_type: post_type
        };

        // Send the AJAX request
        post('delete_comment', formData)
            .then(res => {
                //  show success message

                this.bootstrap.showToast('stToastMessage', res.message);

                // Reload the page after successful comment deletion
                setTimeout(() => {
                    removebutton.find('.st-loader').addClass('d-none');
                    location.reload();
                }, 2000);

                console.log(res);
            })
            .catch(err => {
                // In case of error, reset the button display
                removebutton.find('.st-loader').addClass('d-none');  // Hide loader
                console.log(err);
            });
    }



    DeletPlaylist(event) {
        event.preventDefault();
        this.bootstrap.hideToast('stToastMessage');
        var button = jQuery(event.target);
        var playlist_id = button.data('playlist_id');
        var post_type = button.data('post-type');
        var data = {
            playlist_id: playlist_id,
            post_type: post_type
        };

        post('delete_user_playlist', data)
            .then(res => {
                if (res) {
                    var nearestCol = button.closest('.col');
                    if (nearestCol.length) {
                        nearestCol.remove();

                    }
                }
                this.bootstrap.showToast('stToastMessage', res.message);

            })
            .catch(err => {
                console.log(err);
            })
    }

    InsetUpdatePlaylistForm(event) {
        event.preventDefault();

        const button = jQuery(event.target);
        const playlistId = button.data('playlist-id');
        const playlistName = button.data('playlist-name');
        const postType = button.data('post-type');

        // Pre-fill the form fields with the data attributes
        const modalId = 'creatplaylistModal';
        const modalElement = document.getElementById(modalId);

        if (modalElement) {
            const titleInput = modalElement.querySelector('#st_playlist_title');
            const postTypeSelect = modalElement.querySelector('#st_playlist_post_type');
            const playlistidInput = modalElement.querySelector('#st_playlist_id');

            if (titleInput) {
                titleInput.value = playlistName || ''; // Set playlist name if available
            }

            if (postTypeSelect) {
                // Set the value of the postType select field
                postTypeSelect.value = postType || '';

                // Trigger a change event to make sure the UI reflects the new value
                const changeEvent = new Event('change');
                postTypeSelect.dispatchEvent(changeEvent);

                // Disable the select field if postType is provided, otherwise enable it
                if (postType) {
                    postTypeSelect.disabled = true;  // Disable the select field
                } else {
                    postTypeSelect.disabled = false; // Enable the select field if no post type
                }
            }

            if (playlistidInput) {
                playlistidInput.value = playlistId || ''; // Set playlist id if available
            }
        }

        // Open the modal
        this.bootstrap.showModal(modalId);
    }



}
